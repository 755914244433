@import "../theme.scss";

#formBroker {
	background-color: $color-light-grey;
	padding: 50px $standard-padding-x;

	.mainContent {
		> .inner {
			max-width: 1350px;
			min-height: 500px;
			background-color: $color-white;
			box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
			padding: 40px $standard-padding-x;
			align-content: space-between;
			grid-template-areas: "stack";

			.content {
				max-width: 750px;
				text-align: center;
				width: 100%;

				.title {
					color: $color-blue;
					font-size: clamp(22px, 3vw, 24px);
					font-weight: 600;
					width: auto;
					display: inline-block;

					&::after {
						width: 20%;
						display: block;
						content: "";
						background-color: $color-red-light;
						height: 2px;
						margin-top: 10px;
						border-bottom: unset;
						padding-top: 0;
					}
				}

				.intro {
					margin-top: 20px;
					font-size: 14px;
					font-weight: 500;
				}

				.form {
					max-width: 390px;
					margin-top: 20px;
					text-align: left;
					grid-gap: 20px;
					width: 100%;

					.info {
						font-size: 11px;
						letter-spacing: 0.02em;
						line-height: 1.25;
						color: $color-dark-grey;
						text-align: center;
						padding: 16px 1em 0 1em;
						margin: 0;
					}
				}

				.buttonContainer {
					margin-top: 50px;

					.buttons {
						grid-template-columns: auto auto;
						grid-gap: 20px;

						button {
							min-width: 190px;
							height: 60px;
						}
					}
				}
			}

			.formContainer {
				grid-area: stack;
				display: none;

				&.active {
					display: grid;
				}

				.form {
					&.broker {
						grid-template-columns: repeat(2, 1fr);
						align-items: start;

						> div {
							grid-column: 1 / 3;
							width: 100%;
						}

						.firstName {
							grid-column: 1 / 2;
						}

						.lastName {
							grid-column: 2 / 3;
						}

						.nationality {
							z-index: 8;
						}

						.health {
							z-index: 7;
						}

						.phoneNumber {
							z-index: 6;
						}

						.timeZoneContainer {
							z-index: 5;
						}

						.country {
							z-index: 4;
						}
					}

					&.profile {
						.tradingStartDate {
							z-index: 4;
						}
					}

					&.about {
						.customerIntrosPerQuart {
							z-index: 4;
						}

						.commissionTurnover {
							z-index: 5;
						}

						.companyPosition {
							z-index: 6;
						}

						.incorporationType {
							z-index: 7;
						}

						.catchmentArea {
							z-index: 8;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1379px) {
	#formBroker {
		.progressBar > .inner ul li p .step {
			translate: 0 80px;
		}
	}
}

@media (max-width: $screenBreak-mobile) {
	#formBroker {
		padding-left: 0;
		padding-right: 0;

		.mainContent {
			> .inner {
				.content {
					.buttonContainer {
						width: 100%;

						.buttons {
							grid-template-columns: 1fr 1fr;
							grid-template-areas: "previous next";

							.white {
								grid-area: previous;
							}

							.red {
								grid-area: next;
							}

							button {
								width: 100%;
								min-width: 120px;
							}
						}
					}

					&:first-child {
						.buttonContainer {
							width: 100%;

							.buttons {
								grid-template-columns: 1fr 1fr;
								grid-template-areas: "apply login";

								.black {
									grid-area: apply;
								}

								.white {
									grid-area: login;
								}

								button {
									width: 100%;
									min-width: 120px;
								}
							}
						}
					}
				}

				.formContainer {
					grid-area: stack;
					display: none;

					&.active {
						display: grid;
					}

					.form {
						&.broker {
							grid-template-columns: 1fr;
							grid-template-areas: "firstName" "lastName" "nationality" "health" "phoneNumber" "timeZoneContainer" "email" "country" "livedOutCountryPastTen" "passport" "bill";
						}
					}
				}
			}
		}

		.progressBar {
			> .inner {
				ul {
					// grid-template-columns: repeat(5, auto);
					// justify-content: start;
					// translate: calc(-275px + (50vw - 137.5px));
					display: block;
					width: 10000px;
					font-size: 0;

					li {
						padding-left: 20px;
						padding-right: 20px;
						min-width: calc(100vw / 3);
						width: auto;
						display: inline-block;

						p .step {
							translate: 0 60px;
						}
					}
				}
			}
		}
	}
}

@media (max-width: 400px) {
	#formBroker {
		.mainContent {
			> .inner {
				.content {
					.buttonContainer {
						.buttons {
							button {
								p,
								a {
									padding: 0.5em;
									font-size: 15px;
								}
							}
						}
					}
				}
			}
		}
	}
}
